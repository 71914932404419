.footer {
  background-color: #333;
  color: rgb(255, 255, 255);
  padding: 2rem 0 0; /* Add padding-top, remove bottom padding */
  text-align: left;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  max-width: 120rem; /* 1920px converted to rem */
  margin: 0 auto;
  padding: 0 3rem; /* 48px converted to rem */
}

.footer-contact, .footer-address, .footer-social {
  margin: 1rem; /* 16px converted to rem */
  flex: 1;
  min-width: 15rem; /* 240px converted to rem */
}

.footer-contact p, .footer-address p {
  margin: 0.5rem 0; /* 8px converted to rem */
  font-size: 1rem; /* 16px converted to rem */
  color: rgb(255, 255, 255);
}

.footer-social {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem; /* Add space below icons, same as padding-top */
}

.footer-social a {
  margin: 0 0.75rem; /* 12px converted to rem */
  transition: transform 0.3s;
  font-size: 1rem; /* 16px converted to rem */
}

.footer-social a:hover {
  transform: scale(1.2);
}

/* Trademark section styles */
.footer-trademark {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem 0; /* 8px converted to rem */
  border-top: 0.0625rem solid rgba(255, 255, 255, 0.116); /* 1px converted to rem */
  margin-top: 2rem; /* Add space above the trademark section, same as margin-bottom of icons */
}

.footer-trademark-link {
  display: flex;
  align-items: center; /* Vertically aligns the icon and text */
  margin-right: 0.5rem; /* 8px converted to rem */
  font-size: 1rem; /* 16px converted to rem */
  text-decoration: none; /* Remove underline */
  color: rgb(255, 255, 255); /* Ensure the text color is white */
  transition: color 0.3s, transform 0.3s; /* Smooth hover transition */
}

.footer-trademark-link:hover {
  transform: scale(1.1); /* Hover effect for trademark link */
  color: #25D366; /* Change to WhatsApp green color on hover */
}

.footer-trademark-link p {
  margin: 0 0 0 0.5rem; /* Space to the left of the icon */
  font-size: 0.7rem; /* Adjust text size */
  display: inline; /* Ensures text stays inline with the icon */
  color: rgb(255, 255, 255); /* Ensure the text color is white */
}

.footer-trademark-link svg {
  color: #25D366; /* WhatsApp green color for the icon */
}

.footer-trademark-link:hover svg {
  color: #128c7e; /* Darker WhatsApp green on hover */
}

/* Responsive adjustments */
@media (max-width: 48rem) { /* 768px converted to rem */
  .footer-container {
    padding: 0 2.375rem; /* 38px converted to rem */
  }

  .footer-contact, .footer-address, .footer-social {
    min-width: 15rem; /* 240px converted to rem */
  }

  .footer-contact p, .footer-address p {
    font-size: 0.875rem; /* 14px converted to rem */
  }

  .footer-social a {
    font-size: 0.875rem; /* 14px converted to rem */
    margin: 0 0.625rem; /* 10px converted to rem */
  }

  .footer-trademark p {
    font-size: 0.55rem; /* 14px converted to rem */
  }

  .footer-trademark-link {
    font-size: 0.8rem; /* 14px converted to rem */
  }
}
