/* About.css */
@font-face {
    font-family: "ClashBold";
    src: url('../Fonts/clashbold.ttf') format('truetype'); /* Ensure this path is correct */
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: "Outfit";
    src: url('../Fonts/outfit.ttf') format('truetype'); /* Ensure this path is correct */
    font-weight: normal;
    font-style: normal;
}

/* General Container */
.about-container {
    color: #e0e0e0; /* Light gray for text */
    background-color: #121212; /* Very dark background */
    padding: 0;
    margin: 0; /* Remove side margins */
    box-sizing: border-box;
    font-family: 'Outfit', sans-serif; /* Default font for text */
    min-height: 100vh; /* Ensure the container takes full viewport height */
    display: flex;
    flex-direction: column;
}

/* About Content */
.about-content {
    max-width: 1200px;
    width: 100%;
    margin: 20px auto;
    padding: 0 20px; /* Padding for spacing inside the container */
    flex: 1; /* Allow content to expand to fill available space */
}

/* Mission and Goal Sections */
.mission-goal-section {
    display: flex;
    align-items: center;
    margin-bottom: 40px;
    position: relative; /* For green line positioning */
}

.section-image-left,
.section-image-right {
    width: 40%;
    height: auto;
}

.section-image-left {
    margin-right: 20px;
    border-left: 5px solid #9aca3c; /* Brighter green line for contrast */
    padding-left: 20px; /* Space for the line */
}

.section-image-right {
    margin-left: 20px;
    border-right: 5px solid #9aca3c; /* Brighter green line for contrast */
    padding-right: 20px; /* Space for the line */

}

.text-content {
    width: 60%;
}

h3 {
    font-size: 1.9rem; /* Decreased font size */
    margin-bottom: 20px;
    font-weight: bold;
    font-family: 'ClashBold', sans-serif; /* Font for headings */
    color: #9aca3c; /* Light gray for headings */
}

/* Additional Heading */
.additional-text h3 {
    font-weight: bold;
    color: #9aca3c; /* Brighter green for emphasis */
    margin-bottom: 15px;
    font-family: 'ClashBold', sans-serif; /* Font for headings */
}

/* Paragraph Styling */
p {
    font-size: 1.1rem; /* Decreased font size */
    line-height: 1.6;
    color: #e0e0e0; /* Light gray for paragraphs */
    font-family: 'Outfit', sans-serif; /* Font for paragraphs */
}


/* Statistics Section */
.statistics {
    display: flex;
    justify-content: space-around;
    margin: 40px 0;
}

.stat-item h4 {
    font-size: 3.5rem; /* Decreased font size */
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'ClashBold', sans-serif; /* Font for headings */
    color: #9aca3c; /* Brighter green for numbers */
}

.stat-item p {
    font-size: 1.1rem; /* Decreased font size */
    color: #e0e0e0; /* Light gray for labels */
    font-family: 'Outfit', sans-serif; /* Font for paragraphs */
}
/* Owner Card Section */
.owner-card {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #1f1f1f; /* Slightly lighter dark background */
    border-radius: 1rem;
    padding: 1.5rem 0rem; /* Increased padding for better spacing */
    margin-bottom:3rem;
    box-shadow: 0 0.4rem 0.8rem rgba(0, 0, 0, 0.5);
    text-align: center;
}

.owner-image-container {
    width: 25%; /* Adjusted width for the image */
    background-color: #9aca3c; 
    padding: 1rem;
    padding-bottom: 0rem;
    padding-top: 1.8rem;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 4rem;
}

.owner-image {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.owner-details {
    max-width: 60%;
    text-align: left; /* Align text to the left for better readability */
}

.owner-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #ffffff; /* Green color */
    margin-bottom: 0.2rem;
    font-family: 'ClashBold', sans-serif;
}

.owner-designation {
    font-size: 0.8rem;
    font-style: italic;
    color: #9aca3c;
    margin-bottom: 1rem; /* Space between name and designation */
}

.owner-description {
    font-size: 0.9rem; /* Smaller font size for description */
    color: #ffffff98;
    line-height: 1.6;
    font-family: 'Outfit', sans-serif;
}




/* Responsive Design */
@media (max-width: 768px) {
    .mission-goal-section {
        flex-direction: column;
        text-align: center;
    }

    .section-image-left,
    .section-image-right {
        width: 100%;
        margin: 0 0 20px 0;
    }

    .text-content {
        width: 100%;
    }

    h3 {
        font-size: 1.9rem; /* Adjusted for smaller screens */
    }

    p {
        font-size: 1.1rem; /* Adjusted for smaller screens */
    }

    .statistics {
        flex-direction: column;
    }

    
    .owner-card {
        flex-direction: column;
        padding: 2rem 1rem; /* Adjust padding to be proportional */
        margin-bottom:2.5rem;
    }

    .owner-image-container {
        width: 50%; /* Keep image size proportional */
        height: auto; /* Maintain aspect ratio */
        margin-right: 0;
        margin-bottom: 2rem;
    }

    .owner-details {
        max-width: 90%; /* Ensure it fits the screen but leaves some padding */
        text-align: center; /* Center align for better layout */
    }

    .owner-title {
        font-size: 1.8rem; /* Proportional font size */
    }

    .owner-designation {
        font-size: 1rem; /* Keep the designation size proportional */
        margin-bottom: 1rem; /* Maintain space between title and designation */
    }

    .owner-description {
        font-size: 1rem; /* Slightly reduced font size for readability */
        line-height: 1.4; /* Adjust line-height for better spacing */
    }
}

@media (max-width: 480px) {
    h3 {
        font-size: 1.6rem;
    }

    p {
        font-size: 1rem;
    }
    .owner-card {
        padding: 3rem 1rem; /* Reduce padding slightly for smaller screens */
        margin:3rem 0rem;
    }

    .owner-image-container {
        width: 70%; /* Further adjust image size for mobile devices */
        margin-bottom: 1.5rem;
    }

    .owner-title {
        font-size: 1.2rem; /* Proportional to smaller screens */
    }

    .owner-designation {
        font-size: 0.9rem; /* Reduce slightly */
        margin-bottom: 0.8rem;
    }

    .owner-description {
        font-size: 0.9rem; /* Maintain smaller description text */
        line-height: 1.4;
    }
}
