@font-face {
    font-family: 'Gilroy';
    src: url('../Fonts/clashbold.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  .hero-container {
    position: relative;
    height: 100vh; /* Full viewport height for desktop */
    display: flex;
    align-items: center;
    justify-content: flex-start; /* Align items to the start */
    overflow: hidden;
  }
  
  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .hero-container::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.404)); /* Gradient */
    z-index: 0;
  }
  
  .hero-content {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    justify-content: center; /* Center items vertically */
    padding: 2em; /* Responsive padding */
    border-radius: 1em; /* Adjusted for better visual appeal */
    text-align: left; /* Align text to the left */
    color: white;
    position: relative; /* Ensure content is above the gradient */
    z-index: 1;
    max-width: 90%; /* Prevent content from stretching too wide on larger screens */
  }
  
  /* Heading styles */
  .hero-heading {
    font-family: 'Gilroy', sans-serif; /* Apply Gilroy font */
    font-weight: 600; /* Bold font weight */
    font-size: 2.2rem; /* Default font size (base size) */
    margin-bottom: 1em; /* Spacing below the heading */
    margin-top: 1em;
    margin-left: 4.7em; /* Responsive left margin */
    text-align: left; /* Ensure heading is left-aligned */
  }
  
  /* Highlighted text color */
  .highlight {
    color: #9aca3c; /* Set color for highlighted text */
  }
  
  /* Responsive adjustments */
  @media (max-width: 992px) {
    .hero-container {
      height: 95vh; /* Adjusted height for tablet screens */
    }
  
    .hero-content {
      padding: 1.8em; /* Adjusted padding for tablet screens */
    }
  
    .hero-heading {
      font-size: 1.4rem; /* Reduced by 0.7x for tablet screens */
      margin-left: 3.6em; /* Smaller left margin */
    }
  }
  
  @media (max-width: 768px) {
    .hero-container {
      height: 95vh; /* Further adjusted height for mobile screens */
    }
  
    .hero-content {
      padding: 1.5em; /* Further adjusted padding for mobile screens */
      max-width: 100%; /* Ensure it uses full width */
    }
  
    .hero-heading {
      font-size: 1.3rem; /* Reduced by 0.6x for mobile screens */
      margin-left: 2.2em; /* Smaller left margin */
    }
  }
  
  @media (max-width: 480px) {
    .hero-container {
      height: 95vh; /* Minimal height for very small screens */
    }
  
    .hero-content {
      padding: 1em; /* Minimal padding for very small screens */
    }
  
    .hero-heading {
      font-size: 1.3rem; /* Same size as mobile screens */
      margin-left: 1.1em; /* Minimal left margin */
    }
  }
  