/* Page background and general styling */
.contact-us-page {
  background: #121212;
  font-family: "Outfit", sans-serif;
  color: #ffffff;
  position: relative;
  min-height: 100vh;
  padding: 5% 10%;
  overflow: hidden;
  box-sizing: border-box; /* Ensure padding is included in element's total width and height */
}

/* Container for form and info/map */
.contact-us-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

/* Form styling */
.contact-us-form {
  flex: 1;
  min-width: 300px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  animation: contact-us-fadeIn 1s ease-out;
}

.contact-us-form-group {
  margin-bottom: 20px;
}

.contact-us-form-group label {
  font-family: "ClashBold", sans-serif;
  font-weight: bold;
  margin-bottom: 6px;
}

.contact-us-form-group input,
.contact-us-form-group textarea {
  width: 100%;
  padding: 10px 15px;
  border: none;
  border-bottom: 2px solid #ffffff;
  background: transparent;
  color: #ffffff;
  font-size: 16px;
  outline: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

.contact-us-form-group input:focus,
.contact-us-form-group textarea:focus {
  border-bottom: 2px solid #9aca3c;
  box-shadow: 0 2px 8px rgba(154, 202, 60, 0.3);
}

/* Submit button styling */
.contact-us-submit-button {
  background: #9aca3c;
  color: #ffffff;
  border: none;
  padding: 12px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 6px;
  transition: background 0.3s, transform 0.2s;
  margin-top: 20px;
}

.contact-us-submit-button:hover {
  background: #8cbf2f;
  transform: scale(1.05);
}

/* Contact info text styling */

/* Contact info image styling */
/* Container for contact information and image */
.contact-info {
  display: flex;
  justify-content: space-between; /* Ensure space between text and image */
  align-items: center; /* Center items vertically */
  flex-wrap: wrap; /* Allow wrapping on small screens */
}

.contact-info-text {
  flex: 1; /* Allow text to take available space */
}

/* Contact info image styling */
.contact-info-image {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  flex: 0 0 auto; /* Prevent image from growing */
  margin-left: 20px; /* Space between text and image */
}

.bulb-image {
  max-width: 15.6rem; /* Adjust size as needed */
  height: auto;
}

/* Contact info and map container */
.contact-us-right {
  flex: 1;
  min-width: 300px;
}

/* Contact info styling */
.contact-us-info h2 {
  margin-bottom: 10px;
  font-size: 24px;
}

.contact-us-info p,
.contact-item p {
  font-size: 16px;
}

/* Contact items */
.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

/* Contact icons with hover effect */
.contact-icon {
  margin-right: 10px;
  font-size: 20px;
  transition: color 0.3s;

}

.phone {
  color: #17701a; /* Green color for the phone icon */
}
/* Styles for email icon */
.mail {
  color: #bd7352; /* Email color (blue) */
}
.ws {
  color: #34cf6d; /* WhatsApp color */
}
.fb {
  color: #1877F2; /* Facebook color */
}
.insta{
  color: #C13584; /* Instagram color */
}

.contact-icon:hover {
  color: #9aca3c; /* Apply hover color to icons */
  font-size: 1.5rem;
}

/* Remove hover effect from text */
.contact-item p {
  color: #ffffff;
  margin: 0;
}

.contact-item a {
  color: #ffffff;
  text-decoration: none;
}

/* Social media icons */
.contact-us-social-icons {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.contact-us-social-icons a {
  font-size: 24px;
  color: #ffffff;
  transition: color 0.3s;
}

.contact-us-social-icons a:hover {
  color: #9aca3c; /* Social media icons hover */
}

/* Map styling */
.contact-us-map {
  margin-top: 20px;
}

.contact-us-map iframe {
  width: 100%;
  height: 300px;
  border: none;
}
/* Styles for the contact heading */
.contact-heading {
  font-family: "ClashBold", sans-serif;
  color: #9aca3c; /* Set text color */
  margin-bottom: 20px; /* Space below the heading */
}

/* Responsive styles */
@media (max-width: 1024px) {
  /* Tablet */
  .contact-us-page {
    padding: 15% 20%; /* Increase side padding for tablets */
  }

  .contact-info {
    flex-direction: column; /* Stack text and image vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .contact-info-image {
    margin-left: 0; /* Remove margin on small screens */
    margin-top: 20px; /* Space between text and image */
    order: 2; /* Move image below text */
  }

  .contact-info-text {
    order: 1; /* Keep text above image */
  }

  .contact-us-form-group input,
  .contact-us-form-group textarea {
    font-size: 14px;
    padding: 8px 12px;
  }

  .contact-us-submit-button {
    font-size: 14px;
    padding: 10px 18px;
  }

  .contact-us-social-icons {
    flex-direction: column;
    gap: 15px;
  }

  .contact-us-map iframe {
    height: 350px;
  }
}

@media (max-width: 480px) {
  /* Phone */
  .contact-us-page {
    padding: 10% 10%; /* Increase side padding for phones */
  }

  .contact-info {
    flex-direction: column; /* Stack text and image vertically */
    align-items: flex-start; /* Align items to the start */
  }

  .contact-heading {
    margin-top: 20px; /* Space below the heading */
    font-size: 1.8rem; /* Increase the size of the heading */

  }
  
  .contact-info-image {
    margin: 2rem; /* Space between text and image */
    order: 2; /* Move image below text */
  }

  .contact-info-text {
    order: 1; /* Keep text above image */
  }

  .contact-us-form-group input,
  .contact-us-form-group textarea {
    font-size: 12px;
    padding: 6px 10px;
  }

  .contact-us-submit-button {
    font-size: 12px;
    padding: 8px 16px;
  }

  .contact-us-social-icons {
    gap: 10px;
  }

  .contact-us-map iframe {
    height: 400px;
  }
}
@media (min-width: 1025px) {
  

  .contact-us-form {
    margin-right: 20px; /* Add margin on the right of the form */
    padding-right: 20px; /* Add space inside the form on the right side */
    border-right: 2px solid #9aca3c; /* Green border on the right side */
  }
}
