/* Container for the project cards */
.project-container {
  padding: 4% 6%; /* Vertical padding for spacing; no horizontal padding */
  background: #121212;
}

/* Grid layout adjustments for responsiveness */
.container {
  max-width: 100%; /* Full width */
  padding: 0; /* Remove padding to allow full-width cards */
  margin: 0; /* Remove default margin */
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards within the row */
  margin: 0; /* Remove default margin */
  gap: 1rem; /* Space between rows and columns */
}

/* Adjustments for project cards */
.col-md-4 {
  flex: 0 0 30%; /* Three cards per row on desktop */
  max-width: 30%;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .col-md-4 {
    flex: 0 0 45%; /* Two cards per row on tablets */
    max-width: 45%;
  }
}

@media (max-width: 576px) {
  .col-md-4 {
    flex: 0 0 100%; /* One card per row on mobile devices */
    max-width: 100%;
    padding: 0; /* Remove padding to ensure cards touch the sides */
  }
}
