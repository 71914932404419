/* Navbar container */
.navbar {
  position: fixed;
  width: 100%;
  z-index: 1000;
  background-color: transparent; /* Transparent background by default */
  padding: 0 11rem; /* Reduced padding to decrease height */
  transition: background-color 0.3s ease-in-out;
  display: flex;
  justify-content: space-between; /* Aligns logo and links with space between */
  align-items: center;
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.2); /* Light white bottom outline */
}

/* Add background color when scrolled or expanded */
.navbar.scrolled,
.navbar.expanded {
  background-color: rgba(0, 0, 0, 0.781); /* Dark background when scrolled or expanded */
  box-shadow: 0 2px 4px rgba(255, 255, 255, 0.3); /* Slightly stronger shadow on scroll or expand */
}

/* Navbar links container */
.navbar-links {
  display: flex;
  flex-grow: 1;
  justify-content: center; /* Centers links horizontally */
}

/* Navbar links */
.navbar-nav {
  display: flex;
  flex-grow: 1;
  justify-content: center; /* Centers links horizontally */
  list-style: none;
  padding: 0; /* Remove default padding */
  margin: 0; /* Remove default margin */
}

.navbar-nav .nav-item {
  margin: 0 0.5rem; /* Reduced space between links */
}

/* Navbar links */
.navbar-nav .nav-link {
  color: rgb(255, 255, 255) !important; /* Default link color is white */
  font-size: 1.1rem; /* Keep font size unchanged */
  transition: color 0.3s, background-color 0.3s, font-weight 0.3s;
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  text-align: center; /* Center text in links */
}

/* Hover state for Navbar links */
.navbar-nav .nav-link:hover {
  font-weight: 300; /* Bold text on hover */
}

.navbar-nav .nav-link.active {
  
  color: #9bca3c !important; /* Ensure this rule applies */
  font-weight: 900;
}


/* Logo adjustment for better alignment */
.navbar .logo {
  max-width: 300px; /* Keep logo size unchanged */
  max-height: 105px; /* Reduced max-height to fit slimmer navbar */
}



/* Contact Us button */
.contact-button {
  background-color:#9bca3cd7;/* White background by default */
  color: rgb(0, 0, 0); /* Black text by default */
  font-size: 1.15rem; /* Keep font size unchanged */
  border-radius: 8px; /* Radial corners */
  border-color: #9bca3c;
  padding: 0.25rem 0.85rem; /* Adjust padding */
  transition: background-color 0.3s, color 0.3s, font-weight 0.3s;
}
/* Active state for Contact Us button */
.contact-button.active {
  background-color: #9bca3ce3; /* White background for active state */
  color: black; /* Black text for active state */
  font-weight: 700; /* Bold text for active state */
}
/* Hover state for Contact Us button */
.contact-button:hover {
  background-color: rgba(0, 0, 0, 0); /* Light black background on hover */
  color: #9bca3c; /* Green text on hover */
  font-weight: bold; /* Bold text on hover */
  border: 0.00001px solid rgba(155, 202, 60, 0.39); /* Light green border on hover */
}


/* Set the border color of the navbar toggler button to white */
.navbar-toggler {
  border: 2px solid rgba(122, 122, 122, 0.342); /* Set the border color to white */
  background: transparent; /* Ensure the background is transparent */
}

/* Navbar when toggle button is clicked on mobile */
.navbar.expanded {
  background-color: rgba(0, 0, 0, 0.781); /* Same background color as when scrolled */
}



/* Mobile view adjustments */
@media (max-width: 992px) {
  .navbar {
    padding: 0 1rem; /* Slimmer padding for mobile view */
    height: auto; /* Adjust height based on content */
  }

  .navbar-nav {
    flex-direction: column; /* Stack links vertically on mobile */
    text-align: center; /* Center align text on mobile */
  }

  .navbar-nav .nav-link {
    font-size: 1.1rem; /* Slightly smaller font size for mobile */
  }

  /* Center the Contact Us button on mobile view */
  .contact-button-container {
    text-align: center;
    width: 100%;
  }

  .contact-button {
    display: inline-block;
    margin: 20px auto; /* Center the button with margin on top and bottom */
    font-size: 1.1rem; /* Slightly smaller font size for mobile */
    padding: 0.4rem 1rem; /* Adjust padding */
    margin-bottom: 1.6rem;
  }
}

