.faq-section {
  background: linear-gradient(rgba(0, 0, 0, 0.295), rgba(0, 0, 0, 0.199)),
              url('../Images/faq.webp') 
              no-repeat center center/cover;
  background-attachment: scroll;
  min-height: 70vh;
  padding: 4vh 4vw;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.faq-section-title {
  color: white;
  font-size: 2em;
  text-align: left;
  margin: 0 0 2.4vh 0; /* Removed left margin */
  font-family: 'ClashBold', sans-serif;
  width: 100%;
  max-width: 72vw; /* Ensure the title width matches the container width */
  box-sizing: border-box;
}

.faq-section-container {
  border: 1.6px solid white;
  border-radius: 12px;
  padding: 2vh 3.2vw;
  background-color: rgba(50, 50, 50, 0.541);
  width: 100%;
  max-width: 72vw; /* Ensures consistency with title */
  box-sizing: border-box;
  margin: 1.2vh auto;
  text-align: left;
}

/* Adjust the FAQ container to align with the title */
.faq-container-wrapper {
  width: 100%;
  max-width: 72vw; /* Ensure container width matches title */
  box-sizing: border-box;
  padding-left: 0; /* Align with title */
}

.faq-item-container {
  border-bottom: 1.6px solid white;
  padding: 2vh 0.64vw;
  cursor: pointer;
}

.faq-item-container:last-child {
  border-bottom: none;
}

.faq-question {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2em;
  font-weight: bold;
}

.faq-arrow {
  transition: transform 0.3s ease;
}

.faq-arrow-expanded {
  transform: rotate(180deg);
}

.faq-answer {
  margin-top: 0.8vh;
  background-color: rgba(0, 0, 0, 0.04);
  color: rgb(255, 255, 255);
  padding: 1.2vh 0.8vw;
  font-size: 1em;
}

.bracket-text
{    color: #9aca3c; /* Set color for highlighted text */
}

/* Responsive Design */
@media (max-width: 1024px) and (min-width: 769px) {
  .faq-section-title {
    font-size: 1.7em;
  }
  
  .faq-section-container {
    max-width: 75vw;
    padding: 2vh 2.8vw;
  }
  
  .faq-question {
    font-size: 1em;
  }
  
  .faq-answer {
    font-size: 0.8em;
  }
}

@media (max-width: 768px) {
  .faq-section-title {
    font-size: 1.3em;
  }
  
  .faq-section-container {
    max-width: 76vw;
    padding: 1.6vh 2.4vw;
  }
  
  .faq-question {
    font-size: 0.8em;
  }
  
  .faq-answer {
    font-size: 0.6em;
  }
}
