@font-face {
  font-family: "ClashBold";
  src: url('../Fonts/clashbold.ttf') format('truetype'); /* Update with the correct path to your font file */
  font-weight: bold;
  font-style: normal;
}

.brands-container {
  text-align: center;
  padding: 5vh;
  background-color: #ffffff; /* Background color for the section */
  margin: 0 4.4rem; /* Side margin for desktop */
}

.brands-heading {
  color: rgb(0, 0, 0);
  font-size: 2.4em; /* Adjusted for better visibility on smaller screens */
  text-align: left; /* Align heading to the left */
  margin-bottom: 4vh;
  margin-top: 5vh;
  font-family: "ClashBold", sans-serif; /* Use ClashBold font */
  padding-left: 10.4rem; /* Align with the left side of the slider */
  position: relative;
}

.brands-slider-container {
  margin-bottom: 2vh; /* Space between sliders */
}

.brands-slider-subheading {
  font-family: "Playfair Display", serif;  font-optical-sizing: auto;
  font-weight: 100;
  font-style: italic;
  font-size: 1.6rem; /* Adjusted for smaller devices */
  margin-bottom: 4vh; /* Reduced margin for smaller screens */
  color: #007b43; /* Adjust color as needed */
  text-align: left; /* Align subheading to the left */
  padding-left: 10.4rem; /* Align with the left side of the slider */
  position: relative;
}

.brands-slider {
  background-color: #ffffff; /* Adjust as needed */
  max-width: 90%; /* Reduce the width of the slider */
  overflow: hidden; /* Hide overflow */
  margin: 0 auto; /* Center the slider */
  width: 100%; /* Full width of its container */
  padding: 0; /* Remove padding */
}

.slick-list {
  padding: 0; /* Remove padding from slick-list */
}

.slick-slide {
  display: flex;
  align-items: center; /* Center items vertically */
  justify-content: center; /* Center items horizontally */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

/* Image styling */
.brands-logo {
  max-width: 100%; /* Ensure logos fit within the slider */
  height: auto;
  display: block;
  margin: 0; /* Remove margin to eliminate spacing */
  padding: 0; /* Remove padding to eliminate spacing */
}

/* Responsive adjustments */
@media (min-width: 1024px) {
  .brands-container {
    margin: 0 4.4rem; /* Side margin for desktop */
  }

  .brands-heading,
  .brands-slider-subheading {
    padding-left: 10.4rem; /* Consistent alignment for desktop */
    text-align: left; /* Align text to the left */
  }

  .brands-slider {
    max-width: 80%; /* Further reduce the width on larger screens */
  }

  .brands-logo {
    max-width: 80%; /* Adjust image size on larger screens */
  }

  .slick-slide {
    flex: 1 0 33%; /* Show up to 3 logos per slide */
  }
}

@media (max-width: 1024px) {
  .brands-container {
    margin: 0 3.5rem; /* Adjusted side margin for medium screens */
  }

  .brands-heading,
  .brands-slider-subheading {
    padding-left: 3.5rem; /* Adjust alignment for medium screens */
    text-align: left; /* Ensure text aligns to the left */
  }

  .brands-slider {
    max-width: 85%; /* Adjust width for medium screens */
  }

  .brands-logo {
    max-width: 60%; /* Adjust image size on medium screens */
  }

  .slick-slide {
    flex: 1 0 50%; /* Show up to 2 logos per slide on medium screens */
  }
}

@media (max-width: 768px) {
  .brands-container {
    margin: 0 2.5rem; /* Adjusted side margin for small screens */
  }

  .brands-heading,
  .brands-slider-subheading {
    padding-left: 2.5rem; /* Adjust alignment for small screens */
    text-align: left; /* Ensure text aligns to the left */
  }

  .brands-slider {
    max-width: 90%; /* Adjust width for small screens */
    padding: 0; /* Remove padding if any */
  }

  .brands-logo {
    max-width: 40%; /* Adjust image size on small screens */
  }

  .slick-slide {
    flex: 1 0 33%; /* Show up to 3 logos per slide on small screens */
  }
}

@media (max-width: 480px) {
  .brands-container {
    margin: 0 1.5rem; /* Adjusted side margin for very small screens */
  }

  .brands-heading {
    font-size: 1.6em; /* Further reduced font size for very small screens */
    padding-left: 1.5rem; /* Align with the left side of the slider */
    text-align: left; /* Ensure text aligns to the left */
  }

  .brands-slider-subheading {
    font-size: 1.1rem; /* Further reduced font size for very small screens */
    margin-bottom: 3vh; /* Further reduced margin */
    padding-left: 1.5rem; /* Align with the left side of the slider */
    text-align: left; /* Ensure text aligns to the left */
  }

  .brands-slider {
    max-width: 95%; /* Adjust width for very small screens */
  }

  .brands-logo {
    max-width: 30%; /* Further reduce image size on very small screens */
  }

  .slick-slide {
    flex: 1 0 50%; /* Show up to 2 logos per slide on very small screens */
  }
}
