.carousel {
  position: relative;
  width: 100%;
  padding-top: 40.5%; /* Aspect ratio for 1034x1080 (1034/1080 * 100) */
  overflow: hidden;
  background: linear-gradient(to bottom, #00000052 40%, #000000cb 100%), url('../Images/green.jpg') no-repeat center center;
  background-size: cover; /* Adjust the image to cover the entire area */
}


.carousel-slide {
  position: absolute;
  top: 0;
  left: 50%;
  width: 35%; /* Smaller width for desktop and tablet by default */
  height: 100%;
  background-size: contain; /* Ensure the image is fully visible */
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0; /* Initially hide slides */
  transform: translateX(-50%); /* Center the slide */
  transition: opacity 1s ease-in-out;
}

.carousel-slide.active {
  opacity: 1; /* Show active slide */
}

.carousel-dots {
  position: absolute;
  bottom: 1.3rem;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  width: 0.9rem;
  height: 0.9rem;
  margin: 0 0.3125rem;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dot.active {
  background-color:#9bca3c;
  width: 1.1rem;
  height: 1.1rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .carousel {
    padding-top: 90%; /* Adjust for tablet view */
  }

  .carousel-slide {
    width: 80%; /* Slightly larger width for tablet */
  }
  .carousel-dots {
    bottom: 1.3rem;
  }

  .dot {
    width: 0.6rem;
    height: 0.6rem;
    margin: 0 0.17rem;
  }
  .dot.active {
    width: 0.75rem;
    height: 0.75rem;
  }
}

@media (max-width: 480px) {
  .carousel {
    padding-top: 110%; /* Adjust for mobile view */

  }

  .carousel-slide {
    width: 90%; /* Full width for mobile */
  }

  .dot {
    width: 0.6rem;
    height: 0.6rem;
    margin: 0 0.17rem;
  }
  .dot.active {
    width: 0.75rem;
    height: 0.75rem;
  }
  

  .carousel-dots {
    bottom: 1.25rem;
  }
}
