/* src/components/ScrollToTopButton.css */
.scroll-to-top-button {
  position: fixed;
  bottom: 120px; /* Positioned above the WhatsApp widget */
  right: 20px;
  width: 50px; /* Match the size of the WhatsApp icon */
  height: 50px; /* Match the size of the WhatsApp icon */
  background: linear-gradient(135deg, rgba(154, 202, 60, 0.7), rgba(155, 202, 60, 0.466)); /* Updated gradient with new color */
  color: #fff;
  border: none;
  border-radius: 50%; /* Make the button a circle */
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: background 0.3s, transform 0.3s, opacity 0.3s;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  opacity: 0;
  visibility: hidden;
  z-index: 1000; /* Ensure it appears above the WhatsApp widget */
}
.scroll-to-top-button.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top-button.hidden {
  opacity: 0;
  visibility: hidden;
}

.scroll-to-top-button:hover {
  background: linear-gradient(135deg, rgba(155, 202, 60, 0.836), rgba(154, 202, 60, 0.616)); /* Updated gradient on hover */
  transform: scale(1.1);
}

.scroll-to-top-button:active {
  background: linear-gradient(135deg, rgba(154, 202, 60, 0.6), rgba(154, 202, 60, 0.4)); /* Updated gradient on active */
  transform: scale(0.95);
}

.scroll-to-top-icon {
  font-size: 20px;
  opacity: 0.8; /* Low opacity for the arrow icon */
}
