@font-face {
  font-family: 'Clash';
  src: url('../Fonts/clash.ttf') format('truetype');
}

@font-face {
  font-family: 'Outfit';
  src: url('../Fonts/outfit.ttf') format('truetype');
}

.services-container {
  text-align: center;
  padding: 20px 20px 90px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 40px;
  background: #121212;
}

.service-box {
  position: relative;
  background-color: rgba(50, 50, 50, 0.267);
  border-radius: 8px;
  padding: 35px;
  width: 340px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.288);
  transition: all 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1; /* Ensure it stays below the overlay icon */
}

.service-box h2,
.service-box p {
  color: #fff;
  z-index: 2; /* Ensure text is above the box but below the overlay icon */
  position: relative;
  margin: 0;
  transition: all 0.3s ease;
}

.service-box h2 {
  font-family: 'Clash', sans-serif;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 2.25rem;
  color: #9aca3c;


}

.service-box p {
  font-family: 'Outfit', sans-serif;
  font-size: 1.3rem;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.service-box:hover
{
  background-color: rgba(43, 42, 42, 0.795);

}
.service-box:hover h2
{
  font-size: 2.4rem;

}
 
 

/*
.service-box:hover h2,
.service-box:hover p {
  opacity: 0.3; 
}
 
.overlay-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6rem; 
  height: 6rem; 
  background-color: #9aca3c; 
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3; 
  opacity: 0; 
  transition: all 0.3s ease;
} 

.overlay-icon img {
  width: 70%;
  height: auto;
}

.service-box:hover .overlay-icon {
  width: 9.6rem; 
  height: 9.6rem; 
  opacity: 1; 
}
*/

/* Combined Responsive Adjustments */
@media (max-width: 1200px) {
  .service-box h2 {
    font-size: 2.7rem;
  }
}

@media (max-width: 992px) {
  .service-box {
    width: 360px;
    padding: 42px;
  }
  .service-box h2 {
    font-size: 2.4rem;
  }
}

@media (max-width: 768px) {
  .service-box {
    width: 312px;
    padding: 24px;
  }
  .service-box h2 {
    font-size: 1.8rem;
  }
  .service-box p {
    font-size: 1.56rem;
  }
}

@media (max-width: 576px) {
  .service-box {
    width: calc(100% - 20px);
    padding: 24px;
  }
  .service-box h2 {
    font-size: 1.8rem;
  }
  .service-box p {
    font-size: 1.08rem;
  }
}
