/* src/components/WhatsAppWidget.css */
.whatsapp-widget {
    position: fixed;
    bottom: 60px; /* Positioned above the scroll-to-top button */
    right: 20px;
    background-color: #25d366; /* WhatsApp green color */
    border-radius: 50%; /* Makes the widget a circle */
    width: 50px; /* Set width and height for the circle */
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
    z-index: 999; /* Ensure it appears beneath the scroll-to-top button */
  }
  
  .whatsapp-widget:hover {
    background-color: #128c7e; /* Darker green on hover */
    transform: scale(1.1);
  }
  
  .whatsapp-icon {
    color: #fff;
    font-size: 24px;
    opacity: 0.9; /* Slightly transparent icon */
  }
  