@font-face {
  font-family: "ClashBold";
  src: url('../Fonts/clashbold.ttf') format('truetype'); /* Ensure this path is correct */
  font-weight: bold;
  font-style: normal;
}


.project-item {
  position: relative;
  overflow: hidden;
  border: 0.05625rem solid #03000017;
  transition: box-shadow 0.3s ease-in-out;
  font-family: 'Open Sans', sans-serif;
}

.media-carousel {
  width: 100%;
}

.media-carousel-item {
  position: relative;
  margin: 0; /* Remove margin if any */
}

.media-carousel-media {
  width: 100%;
  height: auto;
  display: block;
  cursor: pointer; /* Add a cursor to indicate dragability */
  margin: 0; /* Remove margin */
  padding: 0; /* Remove padding */
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6); /* Black gradient background */
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 1rem;
  pointer-events: none; /* Allow interaction with underlying media */
}

.overlay-content {
  max-width: 90%;
}

.project-item:hover .overlay {
  opacity: 1;
}


.title {
  font-family: "ClashBold", sans-serif;
  font-weight: 600;
  font-size: 1.8rem;
  margin: 0.45rem 0;
  color: #fff;
}

.kv {
  font-family: "Playwrite AU SA", cursive;
  font-size: 1.08rem;
  margin: 0.9rem 0;
  color:#9aca3c;
}

.description {
  font-size: 1.08rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal-media {
  max-width: 90%;
  max-height: 90%;
  border-radius: 0.45rem;
}

.close {
  position: absolute;
  top: 2rem;
  right: 2rem;
  font-size: 2rem;
  color: white;
  cursor: pointer;
}

/* Dots container */
.dots-container {
  position: relative;
  bottom: -2rem; /* Adjust the position of the dots */
  text-align: center;
  transform: scaleX(0.9); /* Scale the container horizontally to bring dots closer */
  transform-origin: center; /* Ensure scaling happens from the center */
  height: 2rem; /* Increase the height */
}

/* Custom white dots */
.images-dot {
  width: 0.4rem;
  height: 0.4rem;
  background-color: #ffffff9f; /* White color for the dots */
  border-radius: 50%;
  display: inline-block;
  margin: 0.1448rem; /* Keep the margin as is */
  opacity: 0.5; /* Slightly faded dots */
  transition: opacity 0.3s ease;
}

/* Active dot */
.slick-active .images-dot {
  opacity: 1; /* Brighten the active dot */
  background-color: #9aca3c; /* Color for the active dot */
  width: 0.55rem;
  height: 0.55rem;
}



/* Horizontal expansion on desktop */
@media (min-width: 48rem) {
  .details {
    max-height: 22.5rem;
  }

  .additional-media {
    flex-wrap: nowrap;
    max-height: 16.875rem;
    overflow-x: auto;
    overflow-y: hidden;
  }
}

/* Vertical expansion on mobile */
@media (max-width: 48rem) {
  .project-item {
    margin: 0.45rem;
  }

  .title {
    font-size: 1.44rem;
  }

  .kv {
    font-size: 0.9rem;
  }

  .arrow {
    font-size: 1.08rem;
  }

  .description {
    font-size: 0.9rem;
  }

  .media-carousel-media {
    width: 100%;
    margin: 0; /* Ensure no margin */
    padding: 0; /* Ensure no padding */
  }
}
