@font-face {
  font-family: "ClashBold";
  src: url('../Fonts/clashbold.ttf') format('truetype'); /* Ensure this path is correct */
  font-weight: bold;
  font-style: normal;
}

.custom-heading-container {
  text-align: left;
  max-width: 100%;
  margin: auto;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 10%; /* Add horizontal padding */
  box-sizing: border-box; /* Include padding in element's total width and height */
}

/* Style for the heading text */
.custom-heading {
  color: white;
  font-size: 2.6rem;
  font-weight: bold;
  margin: 0;
  margin-bottom: 0.001rem; /* Add space below the heading */
  font-family: 'ClashBold', sans-serif;
}

/* Style for the subheading text */
.custom-subheading {
  color: #9aca3c;
  font-size: 1.8rem;
  margin: 0;
  margin-top: 0.5rem; /* Add space above the subheading */
  font-family: "Playfair Display", serif;
  font-optical-sizing: auto;
  font-weight: 700;
  font-style:italic;
}

/* Responsive styling */
@media (max-width: 768px) {
  .custom-heading {
    font-size: 2rem;
  }

  .custom-subheading {
    font-size: 1.2rem;
  }

  .custom-heading-container {
    padding: 0 5%; /* Reduce horizontal padding on smaller screens */
  }
}

@media (max-width: 480px) {
  .custom-heading {
    font-size: 1.5rem;
  }

  .custom-subheading {
    font-size: 1.1rem;
  }

  .custom-heading-container {
    padding: 0 2%; /* Further reduce horizontal padding on very small screens */
  }
}
